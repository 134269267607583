import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatStepperModule } from '@angular/material/stepper';

import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';

import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';


@NgModule({
  imports: [
    CommonModule,
      MatPaginatorModule,
      MatStepperModule,
      MatTableExporterModule,
      MatTableModule,
      

  ],
  exports: [
    CommonModule,
      MatPaginatorModule,
      MatStepperModule,
      MatTableExporterModule,
      MatTableModule,

  ]
})
export class MaterialModule {}

