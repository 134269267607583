import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, share} from 'rxjs/operators';
import { LoaderService,AlertService,AuthService } from '../service';
import { ToastService } from '../../core/service';
import { ModalController } from '@ionic/angular';
@Injectable()
export class httpInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(
    public auth:AuthService,
    public loader:LoaderService,
    public alert:AlertService,
    public toastCtrl:ToastService,
    public modalController:ModalController
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token =this.auth.getToken();

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json',
          'source-system': 'WebApp',
          'channel': 'LssApp',
          'timeZone': String(new Date().getTimezoneOffset())
        }
      });
    }
    
  if (token) {
      
      request = request.clone({
        setHeaders: {
          'Authorization': "Bearer " + token
        }
      });
    }
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

    this.loader.show();
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loader.hide();
            if(!(event?.body?.success))
            {
              this.toastCtrl.presentToast('Oops !',"There was unexpected error acquired we will to fix that soon <br> please try later",'danger')
            }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.loader.hide();

        switch(error.status) { 
          case 401: { 
            this.alert.presentAlert("Alert","Access Denied please try again with valid data or resource");
           break;
          } 
          case 500:{
            this.alert.presentAlert("Internal server error","Server is not responding Please Try again later");
            break;
          }
          case 404:{
            this.alert.presentAlert("Not Found","Make sure there is no such data or resource is not available");
            break;
          }
          case 400:{
            this.alert.presentAlert("Alert",error.error+" <br>Please Try again");
            break;
          }
          case 0:{
            this.auth.clearStorage();
            this.modalController.dismiss();
            this.alert.presentAlert("Oops ! Sorry for the inconvinence","There was an unexpected issue acquired our developer team working on that please try later ");
            break;
          }
          default: { 
            this.alert.presentAlert("Information"," Unexpected Technical damage Please Try again later");
             break; 
          } 
       } 
       return throwError(error);
      }),share());  
    
    
    }
}