// In this declare the Feild required to Show on pdf
// for thar we need add like this Table name=[{actualObjectfromDB:'Name given for pdf'}]
// to Display Paragraph type feilds with breakline we have add 'ParaBreak' word after the actual Object name like
//For Example: overPerformance is Actual object we need add like overPerformanceParaBreak

export const pdfFeild={
    afterActionReview:[{dateTime:'Date Time'},{siteId:'Site'},{reportedBy:'Reported By'},{incidentType:'Incident Type'},{assignmentName:'Assignment Name'},{location:'Location'},{incidentDescriptionParaBreak:'Description'},{companyActionsParaBreak:'Action taken'}],
    dailyCheckList:[{dateCreated:'Date'},{siteId:'Site'},{userId:'User'},{handOverTo:'Hand over to'},{dailyCheckListDetailsBreak:'Details'}],
    fireIncident:[{incidentDateTime:'Date Time'},{siteId:'Site'},{userId:'User'},{incidentType:'Incident Type'},{blockFloorZone:'Block / Floor / Zone'},{location:'location'},{cause:'Cause'},{involvedSOCount:'Officer Count'},{remarksParaBreak:'Remarks'},{fullDescriptionParaBreak:'Description'},{securityStaff:'Security Manager'},{managementStaff:'Estates/Manager'},{responseDetailsBreak:'Details'}],
    Incident:[{incidentDate:'Date Time'},{siteId:'Site'},{userId:'User'},{reportNo:'Report No'},{location:'Location'},{subject:'Subject'},{incidentDescriptionParaBreak:'Description'},{followUpActionTakenParaBreak:'Follow up actions'},{victimParticulars:'Victim'}],
    jobAppraisal:[{appraisalDate:'Date'},{userId:'User'},{employeeId:'Employee'},{appraisalPeriod:'Period'},{overallRating:'Ratings'},{empStrengthsParaBreak:'Strengths'},{empPerformanceAreasParaBreak:'Improvement Areas'},{planOfActionParaBreak:'Plan of Action'},{performanceReviewDetailsBreak:'Details'}],
    lostAndFound:[{dateTime:'Date Time'},{siteId:'Site'},{userId:'User'},{informant:'Informant'},{location:'Location'},{contactNo:'Contact No'}],
    officerFeedback:[{dateCreated:'Date'},{siteId:'Site'},{userId:'Officer'},{contactNo:'Contact No'},{commentsParaBreak:'Comments'}],
    jobTraining:[{trainingDate:'Date'},{siteId:'Site'},{mentorId:'Mentor'},{traineeId:'Trainee'},{trainingPeriod:'Period'},{overallRating:'Score'},{location:'Location'},{trainingProgressDetailsBreak:'Details'}],
    operationalVisit:[{visitDate:'Date Time'},{siteId:'Site'},{conductedBy:'Conducted By'},{overallPerformance:'Performance'},{feedbackFromSiteOfficerParaBreak:'Site Officer Feedback'},{commentsRecommendationsParaBreak:'Comments'},{clientName:'Client Name'},{clientDesignation:'Client Designation'},{securityOfficersmemList:'Security Officers'},{officerRatingsDetailsBreak:'Points'},{officerDutiesDetailsBreak:'Duties'}],
    performanceEvalution:[{dateCreated:'Date Time'},{employeeId:'Employee'},{evaluatorId:'Evaluator'},{reviewPeriod:'Review Period'},{objectivesParaBreak:'Objectives'},{accomplishmentsParaBreak:'Accomplishments'},{performanceSummaryParaBreak:'Performance Summary'},{developmentPlanParaBreak:'Development Plan'},{nextYearTargetParaBreak:'Next Year Target'},{overallPerformanceParaBreak:'Overall Performance'},{performanceCriteriaDetailsBreak:'Details'}],
    Supervisor:[{dateTime:'Date'},{securitySupervisorId:'Supervisor'},{addressParaBreak:'Address'},{otherRemarksParaBreak:'Other Remarks'},{occurrenceBookDetailsBreak:'Details'},{turnoutBearingsDetailsBreak:"Turnout Bearings"}],
    siteVisit:[{timeIn:'Date Time'},{siteId:'Site'},{visitedUserId:"conductedBy"}],
    refreshTraining:[{trainingDate:'Date'},{conductedBy:'Conducted By'},{trainingTopic:'Topic'},{trainingDuration:'Duration'},{attendeesmemList:'Security officers'}],
    vacationReport:[{dateCreated:"Date"},{employeeId:'Employee'},{approverId:"Reviewer"},{status:"Status"},{leaveTypeCode:"Type"}],
    occurrence:[{siteId:'Site'},{userId:'User'},{dateTime:'Date Time'},{subject:'Subject'},{occurrence:'Occurrence'}],
    attendance:[{siteId:'Site'},{userId:'User'},{duty:'Duty'},{startTime:'Start Work'},{breakTime:'Break Time'},{resumeTime:'Resume Time'},{endTime:'End Work'}],
    clocking:[{dateCreated:"Date"},{siteId:'Site'},{points:'points'},{planTime:'plan Time'},{clockingDataDetailsBreak:'Details'}]

}