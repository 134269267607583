import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';
import { AlertService, AuthService, LoaderService } from '../../core/service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(private alertCtrl:AlertService,private menuController:MenuController,private navCtrl:NavController,private loader:LoaderService,private auth:AuthService) { }

  loggingOut(){
    this.alertCtrl.presentAlertConfirm("Log out ","Are sure to logging out from this Application?",()=>{
this.auth.clearStorage();
      this.menuController.enable(false);
      // setTimeout(() => {
        this.navCtrl.navigateRoot('/login');  
      // }, 1000);
      
    }),'Log out'
  }
}
