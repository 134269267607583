import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }
  async presentToast(header:string,message:string,color:string) {
    const toast = await this.toastController.create({
      animated: true,
      buttons: [
        {
          side: 'start',
          icon: color=='success'?'checkmark-circle':'alert-circle-outline',
        },
        {
          side: 'end',
          icon: 'close',
        },
        
      ],
      color: color,
      duration: color=='success'?3000:4000,
      header: header,
      keyboardClose: true,
      message: message,
      mode: 'ios',
      position:'middle',
    });
    toast.present();
  }
}
