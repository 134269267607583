import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent implements OnInit {

  pdfSource = "../assets/pdf/documentation.pdf";

  constructor() { }

  ngOnInit() {}


}
