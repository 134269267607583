import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ChangeDetectorRef, EventEmitter, Input, Output} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { CommonModalService } from '../../service/common-modal.service';
import {tableHeader} from '../../../config/ReportTableConstVaribale';
import { AuthService, ExcelGenerateService, PDFGenerateService, ToastService } from '../../service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() report:boolean;
  displayedColumns: string[];
  dataSource= new MatTableDataSource();
  recordData:any=[];
  @Input('header') header:any=[];
  @Input('ExportRequired') export:boolean=true;
  @Input('DeleteRequire')DeleteRequire:boolean=true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() tableValue = new EventEmitter();
  @Output() pdfConstruct = new EventEmitter();
  @Input('EditRequire')EditRequire:boolean=true;
  @Input('UploadRequire')UploadRequire:boolean=false;
  @Input('searchRequire')searchRequire:boolean=false;
  NoData:boolean;
  userData:any=[];
  send_date=new Date();
  siteData: any=[];
  filter: any;
  count: number=0;
  userRole: any;

  constructor(private commonService:CommonModalService,
    private DatePipe:DatePipe,private excel:ExcelGenerateService,
    private authService:AuthService,
    private pdf:PDFGenerateService,private toastCtrl:ToastService){
  }

  ngOnInit(): void {

    if(this.commonService.allSiteData.length==0)
    {
      this.commonService.getSiteDetails().subscribe(res=>{
        this.commonService.allSiteData=res;
        this.siteData=cloneDeep(this.commonService.allSiteData);
        if(this.report)
        {
          this.siteData.unshift({id:'',siteName:'All Site'})
        }
  
      });
    }
    else{
    this.siteData=this.commonService.allSiteData;  
    }
    this.userData=this.commonService.allUserData;

    this.userRole=this.authService.getDecodedDetails()?.typ;

  }

  downloadFile(id){
    this.commonService.downloadFile(id);
  }

  getRecord(row,option)
  { 
    this.tableValue.emit({value:row,option:option});
  }
  refreshData(){
    this.count=0
    this.displayedColumns=tableHeader[this.header];
    this.dataSource.data = this.recordData;
    this.dataSource.paginator = this.paginator;
    if(this.recordData.length!=0)
    {
      this.NoData=false;
    }
    else{
      this.NoData=true;
    } 
  }
  getSite(value){
    return  this.commonService.getSite(value);
  }
  getUserName(value){
    return   this.commonService.getUserName(value);
  }
  headerSplit(value){
    let a= value.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })
    if(value.indexOf('date')>=0 ||value.indexOf('Date')>0||value.indexOf('Time')>0||value.indexOf('time')>0 )
    {
      if(value=='fromDate')
    {
      return 'Program Date'
    }
      return 'Date';
    }
    if(value=='id')
    {
      return 'S.no'
    }
    
    return  a.replace('Id',"");
  }
  validateData(value,key){
      if(key=='id')
      {
        return this.count=this.count+1;
      }
      if(key=='Time')
      {
        return this.DatePipe.transform(value, 'HH:mm');
      }
      if(key=='startTime')
      {
        return this.DatePipe.transform(value, 'dd/MM/yyyy - hh:mm a');
      }
      if(key=='endTime')
      {
        return this.DatePipe.transform(value, 'dd/MM/yyyy - hh:mm a');
      }
      if(key.indexOf('Id')>0||key.indexOf('handOverTo')>=0||key.indexOf('By')>=0)
      {
        return this.getUserName(value);
      }
      else if(key.indexOf('date')>=0 ||key.indexOf('Date')>0||key.indexOf('Time')>0 )
      {
        return this.DatePipe.transform(value, 'dd/MM/yyyy');
      }
      else if(key.indexOf('time')>=0){
        return this.DatePipe.transform(value, 'dd/MM/yyyy   HH:mm');

      }
      else
      {
        return value?value:'N/A';
      }
  }

  generatePdf(){
    if(this.recordData.length<=10000)
    {
    if(this.header=='occurrence'||this.header=='attendance')
    {

    this.pdf.getPDfTable(this.header,this.recordData);  
    }
    else{
      this.pdf.generateDocumentPDF(this.header,this.recordData);  
    }

  }
  else{
    this.toastCtrl.presentToast("Warning !","Size of the extracting of data is too large please try again later","warning")
  }
  }
  generateExcel(){

    if(this.recordData.length<=10000)
    {
      
      if(this.header == "attendance" || this.header =="lostAndFound" || this.header =="refreshTraining" || this.header =="siteVisit" || this.header =="occurrence")
      {
      switch(this.header)
      {  
        case 'attendance':{
          var i=1;
          var exceldata=Array<{S_No:Number,Site:string,User:string,Start_Work:string ,break_Time:string,resume_Time:string,End_Work:string}>();
          this.recordData.forEach(data => {
            
          
            exceldata.push({
              S_No:i,
              Site:this.getSite(data.siteId),
              User:this.getUserName( data.userId),
              Start_Work: this.DatePipe.transform(data.startTime,'dd/MM/yyyy - hh:mm a'),
              break_Time:this.DatePipe.transform(data.breakTime,'dd/MM/yyyy - hh:mm a'),
              resume_Time:this.DatePipe.transform(data.resumeTime,'dd/MM/yyyy - hh:mm a'),
              End_Work:this.DatePipe.transform(data.endTime,'dd/MM/yyyy - hh:mm a')
            });
            i++;
            
          });
          //console.log(exceldata);
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exceldata);
              //console.log('worksheet',worksheet);
              const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
              worksheet["!cols"] = [ { wch: 5 },{ wch:25},{ wch:35},{ wch:25},{ wch:25},{ wch:25},{ wch:25} ]; // set column width with characters
              const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
              this.excel.saveAsExcelFile(excelBuffer, this.header);    
          break;
         }

        case 'lostAndFound':{
          var i=1;
          var Exceldata=Array<{S_No:Number,Date:string,Site:string,User:string,Informant:string,Location:string,Contact_No:string}>();
          this.recordData.forEach(data => {
            
          
            Exceldata.push({
              S_No: i,
              Date: this.DatePipe.transform(data.dateTime,'dd/MM/yyyy - hh:mm a'),
              Site: this.getSite(data.siteId),
              User:this.getUserName( data.userId),
              Informant: data.informant,
              Location: data.location,
              Contact_No: data.contactNo
            });
            i++;
            
          });
          //console.log(Exceldata);
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(Exceldata);
              //console.log('worksheet',worksheet);
              const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
              const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
              this.excel.saveAsExcelFile(excelBuffer, this.header);    
          break;
         }

        case 'refreshTraining':{
          var i=1;
          var excelData=Array<{S_No:Number,Date:string,Conducted_By:string,Topic:string,Duration:string,Security_Officer:string}>();
          this.recordData.forEach(data => {
            
          
            excelData.push({
              S_No:i,
              Date:this.DatePipe.transform(data.trainingDate,'dd/MM/yyyy - hh:mm a'),
              Conducted_By:this.getUserName(data.conductedBy),
              Topic:data.trainingTopic,
              Duration:data.trainingDuration,
              Security_Officer:this.getUserName( data.attendees),
            });
            i++;
            
          });
          //console.log(excelData);
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
              //console.log('worksheet',worksheet);
              const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
              const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
              this.excel.saveAsExcelFile(excelBuffer, this.header);
          break;
         }

        case 'siteVisit':{
          var i=1;
          var excelData2=Array<{S_No:Number,Date:string,Site:string,Conducted_By:string}>();
          this.recordData.forEach(data => {
                   
                 
                   excelData2.push({
                     S_No:i,
                     Date:this.DatePipe.transform(data.dateCreated,'dd/MM/yyyy - hh:mm a'),
                     Site:this.getSite(data.siteId),
                     Conducted_By:this.getUserName(data.visitedUserId)
                   });
                   i++;
                   
                 });
                 //console.log(excelData2);
                 const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData2);
                     //console.log('worksheet',worksheet);
                     const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                     const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                     this.excel.saveAsExcelFile(excelBuffer, this.header);
          break;       
         }
         
         case 'occurrence':{
          var i=1;
          var excelData3=Array<{S_No:Number,Site:string,User:string,Date:string ,Subject:string,Occurence:string}>();
          this.recordData.forEach(data => {
            
          
            excelData3.push({
              S_No:i,
              Site:this.getSite(data.siteId),
              User:this.getUserName( data.userId),
              Date: this.DatePipe.transform(data.dateTime,'dd/MM/yyyy - hh:mm a'),
              Subject:data.subject,
              Occurence:data.occurrence
            });
            i++;
            
          });
          //console.log(excelData3);
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData3);
              //console.log('worksheet',worksheet);
              const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
              const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
              this.excel.saveAsExcelFile(excelBuffer, this.header);    
          break;
         }

      } 
    }

    else{
      this.excel.generateDocumentExcel(this.header,this.recordData);
       }

}
else{
this.toastCtrl.presentToast("Warning !","Size of the extracting of data is too large please try again later","warning")
}


  }

}
