import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { map, shareReplay } from 'rxjs/operators';
import { DataFactory } from 'src/app/core/datafactory/data-factory';
import { CommonModalService } from 'src/app/core/service';
import { Server } from '../../../server/server';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {

  Menu:Array<any>=[];
  dp:string='assets/default profile/default profile.png';
  notifications:Array<any>=[];
  constructor(private dataFactory:DataFactory,private modalController: ModalController,private commonService:CommonModalService) { }

  getSideMenu(x){
     this.dataFactory.getMethod(Server.sidemenu+"?role="+x).pipe(shareReplay()).subscribe((res:any)=>{
       this.Menu=res?.data;
       let m={"id":"","name":"Documentation","icon":"document-text","path":"/documentation","isActive":1,"role":"SO, OE, HR, ADMIN","sortNumber":0,"subMenu":null}
      this.Menu.push(m);
      });

    }
   getUserProfilePic(userName){
     this.dataFactory.postMethod(Server.getUserDetails+'?name='+userName).pipe(map((res:any)=>res?.data!=null?res?.data:[]))
     .subscribe(res=>{
      if(res[0].fileId){
       this.dataFactory.getMethod(Server.download+"?_id="+res[0].fileId).subscribe((image:any)=>{
        this.dp=image.data[0].source;
      })}
     })
   }
   async openNotification(){
    const {NotificationModalComponent}=await import('../../../../modules/notification-modal/notification-modal.component');
    let modal=await this.modalController.create({
      component:NotificationModalComponent,
      cssClass:'small-modal',
      componentProps:{notificationdetails:this.notifications}
    })
    modal.present();
  }

   uploadUserProfile(params){
     return this.dataFactory.putMethod(Server.uploadUserProfile,params);
   }
   getNotification(id){
     return this.dataFactory.getMethod(Server.nofication+"?_id="+id).pipe(map((res:any)=>res?.data!=null?res?.data:[]));
   }
   updateNotification(id){
    return this.dataFactory.putMethod(Server.Acknowlegdenofication+"?id="+id,"")
   }

}
