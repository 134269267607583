import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { ScrollbarThemeModule } from '../directives/ScrollbarThemeModule';
import { PopoverComponent } from './popover/popover.component';
import { MaterialModule } from '../../shared/material/material.module';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { TableComponent } from './table/table.component';
import { SiteSearchModalComponent } from './site-search-modal/site-search-modal.component';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { UserSearchModalComponent } from './user-search-modal/user-search-modal.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ByPassSecurityPipe } from '../pipe/by-pass-security.pipe';
import { FullImageViewComponent } from './full-image-view/full-image-view.component';



@NgModule({
  declarations: [

    ShowHidePasswordComponent,
    PopoverComponent,
    NavigationComponent,
    FooterComponent,
    TableComponent,
    SiteSearchModalComponent,
    ReportHeaderComponent,
    UserSearchModalComponent,
    FullImageViewComponent,
    ByPassSecurityPipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    ScrollbarThemeModule ,
    MaterialModule,
    MatTableExporterModule

  ],
  exports: [

    ShowHidePasswordComponent,
    NavigationComponent,
    IonicModule,
    FooterComponent,
    TableComponent,
    ReportHeaderComponent,
    ScrollbarThemeModule,
    ByPassSecurityPipe
    
  ],
  providers:[
    DatePipe
  ]

})
export class sharedComponentModule { }
