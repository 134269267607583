import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '../service';

@Injectable({
  providedIn: 'root'
})
export class DataFactory {

  constructor(private http:HttpClient) { }

  getMethod(url:string){    
   return  this.http.get(url);
  }
  postMethod(url:string,params?:any)
  { 
   return this.http.post(url,params);
  }
  deleteMethod(url:string)
  {
    return this.http.delete(url);
  }
  putMethod(url:string,params:Object){
    return this.http.put(url,params)
  }
}
