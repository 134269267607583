import { Component, OnInit,Input, OnChanges } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { SideMenuService } from 'src/app/core/sharedComponent/navigation/service/side-menu.service';
import { OverTimePage } from '../over-time/over-time.page';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {


  @Input() notificationdetails:Array<any>=[];
  constructor(private modalController:ModalController,private sideMenService:SideMenuService,
    private navController:NavController
    ) { }

  ngOnInit() {
    const id= setTimeout(()=>{
      if(this.notificationdetails.length!=0)
      {
       this.notificationdetails=this.notificationdetails.sort((a,b) => (a.dateCreated > b.dateCreated) ? 1 : ((b.dateCreated > a.dateCreated) ? -1 : 0)).reverse()
      }
      
      },200)
      //clearTimeout(id)
      const modalState = {
        modal : true,
        desc : 'fake state for our modal'
      };
      history.pushState(modalState, null);
  
  }

  acknowledge(id,i)
  {
    this.sideMenService.updateNotification(id).subscribe((res:any)=>{
      if(res?.data)
      {
        
        this.notificationdetails.splice(i,1)
      }
    })
  }
  async view(notificationId,reportId,title){
    this.closeModal();
    // {type: 'After Action Review', img: 'ic_input', url: a => defUrl('after-action-review', a)},
    //     {type: 'Daily Checklist', img: 'ic_format_list_checks', url: a => defUrl('daily-checklist', a)},
    //     {type: 'Fire Drill', img: 'ic_alert', url: a => defUrl('fire-incident', a)},
    //     {type: 'Incident Report', img: 'ic_alert_octagon', url: a => defUrl('incident-report', a)},
    //     {type: 'Job Appraisal', img: 'ic_wallet_giftcard', url: a => defUrl('job-appraisal', a)},
    //     {type: 'Lost and Found', img: 'ic_find', url: a => defUrl('lost-and-found', a)},
    //     {type: 'Officer Feedback', img: 'ic_message_alert', url: a => defUrl('officer-feedback', a)},
    //     {type: 'On The Job Training', img: 'ic_account_check', url: a => defUrl('on-job-training', a)},
    //     {type: 'Operation Visit', img: 'ic_note_text', url: a => defUrl('operation-visit', a)},
    //     {type: 'Performance Eval', img: 'ic_trending_up', url: a => defUrl('performance-evaluation', a)},
    //     {type: 'Refresher Training', img: 'ic_refresh', url: a => defUrl('refresher-training', a)},
    //     {type: 'Shift Supervisor Checklist', img: 'ic_format_list_checks', url: a => defUrl('shift-supervisor-checklist', a)},
    //     {type: 'Site Visit', img: 'ic_note_text', url: a => defUrl('site-visit', a)},
    //     {type: 'Training', img: 'ic_teach', url: a => defUrl('view-training', a)},
    //     {type: 'Vacation Request', img: 'ic_restore_clock', url: a => defUrl('approve-vacation', a)},
    //     {type: 'Management Feedback', img: 'ic_voice', url: a => defUrl('management', a)},
    //     {type: 'Management Warning', img: 'ic_voice', url: a => defUrl('management', a)},
    //     {type: 'e-Learning', img: 'ic_file_video', url: a => defUrl('view-learning', a)},
    //     {type: 'e-Learning Failed', img: 'ic_file_video', url: a => `learning-result/${a.metaData.eLearningId}/${a._id}/${a.id}`},
    //     {type: 'e-Learning Response', img: 'ic_file_video', url: a => `learning-response/${a.metaData.eLearningId}/${a._id}/${a.id}`},
    //     {type: 'e-Learning View', img: 'ic_file_video', url: a => defUrl('learning-report', a)},
    //     {type: 'OverTime', img: 'ic_clock_alert', url: a => defUrl('overtime', a)},

    switch(title){
      case 'After Action Review':{
        this.navController.navigateForward('/Reports/after-action-review',{state:{notificationId:notificationId,reportId:reportId}})
        break;

      }
      case 'Fire Drill':{
        this.navController.navigateForward('/Reports/fire-incident',{state:{notificationId:notificationId,reportId:reportId}})
        break ;
      }
      
      case 'Lost and Found':{
        this.navController.navigateForward('/Reports/lost-and-found',{state:{notificationId:notificationId,reportId:reportId}})
        break ;
      }
      case 'Officer Feedback':{
        this.navController.navigateForward('/Reports/officer-feedback',{state:{notificationId:notificationId,reportId:reportId}})
        break ;
      }
      
      case 'Operation Visit':{
        this.navController.navigateForward('/Reports/operation-visit',{state:{notificationId:notificationId,reportId:reportId}})
        break ;
      }
      
      case 'Performance Eval':{
        this.navController.navigateForward('/Reports/performance-evaluation',{state:{notificationId:notificationId,reportId:reportId}})
        break ;
      }
      
      case 'Refresher Training':{
        this.navController.navigateForward('/Reports/refresher-training',{state:{notificationId:notificationId,reportId:reportId}})
        break ;
      }
      
      case 'Supervisor Checklist':{
        this.navController.navigateForward('/Reports/supervisor-checklist',{state:{notificationId:notificationId,reportId:reportId}})
        break ;
      }
      
      case 'Site Visit':{
        this.navController.navigateForward('/Reports/site-visit',{state:{notificationId:notificationId,reportId:reportId}})
        break ;
      }
      case 'On The Job Training':{
        this.navController.navigateForward('/Reports/on-job-training',{state:{notificationId:notificationId,reportId:reportId}})
        break ;
      }
      case 'Job Appraisal':{
        this.navController.navigateForward('/Reports/job-appraisal',{state:{notificationId:notificationId,reportId:reportId}})
        break ;

      }
      case "Incident Report":
        {
          this.navController.navigateForward('/Reports/incident-report',{state:{notificationId:notificationId,reportId:reportId}})
          break;
        }
        case "Daily Checklist":
          {
            this.navController.navigateForward('/Reports/daily-checklist',{state:{notificationId:notificationId,reportId:reportId}})
            break;
          }
          case "e-Learning":
            {
              this.navController.navigateForward('/Employee%20Management/elearning-response',{state:{notificationId:notificationId,reportId:reportId}})
              break;
            }
            case "e-LearningResponse":
              {
                this.navController.navigateForward('/HR Bay/e-Learning-Report',{state:{notificationId:notificationId,reportId:reportId}})
                break;
              }
              case "Vacation":
                {
                  this.navController.navigateForward('/HR Bay/vacation-report',{state:{notificationId:notificationId,reportId:reportId}})
                  break;
                }
              case "OccurrenceBook":
            {
              this.navController.navigateForward('/Site%20Management/occurrence-book',{state:{notificationId:notificationId,reportId:reportId}})
              break;
            }
            case "New-Site":
              {
                this.navController.navigateForward('/Site%20Management/site-dashboard',{state:{notificationId:notificationId,reportId:reportId}})
                break;
              }
            case "ClassRoom-Training":
              {
                this.navController.navigateForward('/HR Bay/classroom-training',{state:{notificationId:notificationId,reportId:reportId}})
                break;
              }
        case "OverTime":
          {
            let modal=await this.modalController.create({
              component:OverTimePage,
              cssClass:'small-modal',
              componentProps:{notificationId:notificationId,reportId:reportId,title:title}
            })
            modal.present();
            break;
          }
          case "KET Contract":
          {
            let modal=await this.modalController.create({
              component:OverTimePage,
              cssClass:'small-modal',
              componentProps:{notificationId:notificationId,reportId:reportId,title:title}
            })
            modal.present();
            break;
          }
    }

  }

  closeModal(){
    this.modalController.dismiss();
  }

}
