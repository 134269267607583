import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { DataFactory } from 'src/app/core/datafactory/data-factory';
import { Server } from 'src/app/core/server/server';
import { CommonModalService } from 'src/app/core/service';
import { ReportService } from '../reports/service/report.service';

@Component({
  selector: 'app-over-time',
  templateUrl: './over-time.page.html',
  styleUrls: ['./over-time.page.scss'],
})
export class OverTimePage implements OnInit{

  @Input() notificationId:string;
  @Input() reportId:string;
  @Input() title:string;
  attendanceReport:any;
  overtime:any;
  constructor(private modalController: ModalController, private datafactory:DataFactory,private reportService:ReportService,private commonService:CommonModalService) { }

  ngOnInit() {
    //console.log(this.title)
    if(this.title=="OverTime")
    {
    this.datafactory.getMethod(Server.report.attendance.getbyid+'?_id='+this.reportId)
    .subscribe((res:any)=>{
      this.attendanceReport=res.data[0];
      //console.log(res.data[0])
      if(this.attendanceReport==undefined){
        this.datafactory.getMethod(Server.hrbay.overtime+'?_id='+this.reportId).subscribe((res:any)=>{
          this.overtime=res.data[0];
        })
      }
    })
  }
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    

  }

  findUser(value){
    return this.commonService.getUserName(value);
  }
  closeModal(){
    this.modalController.dismiss();
  }
  findSite(value){
    return this.commonService.getSite(value);
  }
  acknowledge(){
    this.closeModal();
    this.reportService.acknowledge(this.notificationId)
  }
}
