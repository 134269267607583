import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataFactory } from '../../../core/datafactory/data-factory';
import { Server } from '../../../core/server/server';
import { login } from '../model/login.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private datafactory:DataFactory) { }


  sendLogin(loginParams:UntypedFormGroup){
    return this.datafactory.postMethod(Server.authenticateUser,loginParams.value);
  }
  getCompanyDetails(){
    return this.datafactory.getMethod(Server.companyDetails).pipe(map((res:any)=>res.data));
  }
}
