import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DashboardService } from '../service/dashboard.service';
@Injectable({
  providedIn: 'root'
})
export class DashboardResolverService  {

  constructor(private dashBoardService:DashboardService) { }

  

    resolve(route: ActivatedRouteSnapshot){
      return this.dashBoardService.getCardDetails();

    }
  
}
