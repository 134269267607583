import { environment } from "../../../environments/environment";
export const Server={


    addclientsatisficationform:environment.apiUrl+"/Clientsatifactions/add",

    // notification
    nofication:environment.apiUrl+"/Notification/Getbyid",
    Acknowlegdenofication:environment.apiUrl+"/Notification/update",
    //file upload and download
    download:environment.apiUrl+"/fileMetaData/Getbyid",
    downloadKET:environment.apiUrl+"/fileMetaData/GetbyKETid",
     //KET
     KET:environment.apiUrl+"/KET/add",
     KETGet:environment.apiUrl+"/KET/Get",
    //company details
    companyDetails:environment.apiUrl+"/LssDetails/getLssDetails",
    //getImage
    getImage:environment.apiUrl+"/Base64Images/Getbyid",
    // user
    autogenerate:environment.apiUrl+"/Users/Generate",
    authenticateUser:environment.apiUrl+"/Users/AuthenticateUser",
    generateOTP:environment.apiUrl+"/Users/getotp",
    updatePasword:environment.apiUrl+"/Users/updateUserPassword",
    AddUserDetails:environment.apiUrl+"/Users/AddUser",
    updateUserDetails:environment.apiUrl+"/Users/update",
    getUserDetails:environment.apiUrl+"/Users/getUserDetails",
    getUserNames:environment.apiUrl+"/Users/GetUserNames",
    searchWithUserName:environment.apiUrl+"/Users/searchWithUserName",
    getAllUserName:environment.apiUrl+"/Users/GetAllUserName",
    getUserVacationBalance:environment.apiUrl+"/VacationBalance/getByUserId",
    getUserSalaryStructure:environment.apiUrl+"/SalaryStructure/Getbyid",
    getUserFeedBackDetails:environment.apiUrl+"/Feedback/Getbyid",
    sendUserFeedBack:environment.apiUrl+"/Feedback/add",
    getVacationReport:environment.apiUrl+"/Vacations/getReport",
    getOvertimeReport:environment.apiUrl+"/OverTimes/getReport",
    addVacation:environment.apiUrl+'/Vacations/add',
    addOverTime:environment.apiUrl+'/OverTimes/add',
    deleteuserProfile:environment.apiUrl+'/Users/delete',
    uploadUserProfile:environment.apiUrl+'/Users/updateUserProfile',
    UserOE_HR:environment.apiUrl+'/Users/getHR&OE',
    updateKET:environment.apiUrl+'/Users/UpdateKET',
    //vacation  type
    getVacationType:environment.apiUrl+'/LeaveType/lookUp',
    //grade
    getGradeDetails:environment.apiUrl+'/Grades/Lookup',
    //site management 
    site:{
    getSiteDetails:environment.apiUrl+"/Site/getNames",
    getSiteAllDetails:environment.apiUrl+"/Site/getSiteDetails",
    sendSiteDetails:environment.apiUrl+"/Site/add",
    deleteSiteDetails:environment.apiUrl+'/Site/delete',
    updateSiteDetails:environment.apiUrl+'/Site/update',
    searchWithSiteName:environment.apiUrl+"/Site/searchWithSiteName",
    getOccuranceReport:environment.apiUrl+"/OccurrenceBook/getReport",
    addOccuranceReport:environment.apiUrl+"/OccurrenceBook/add",
    updateOccuranceReport:environment.apiUrl+"/OccurrenceBook/update",
    getOccurrenceById:environment.apiUrl+"/OccurrenceBook/Getbyid",
    getClockingPoints:environment.apiUrl+'/ClockingPoints/get',

    getclientquestion:environment.apiUrl+'/Clientsatificationfeedback/Lookup',
    addclientquestions:environment.apiUrl+'/Clientsatificationfeedback/addclientquestions',
    updateclientquestions:environment.apiUrl+'/Clientsatificationfeedback/updateclientquestions',
    deleteclientquestions:environment.apiUrl+'/Clientsatificationfeedback/delete',

    clocking:{
        get:environment.apiUrl+"/ClockingReports/get",
        getById:environment.apiUrl+"/ClockingReports/Getbyid",
        update:environment.apiUrl+"/ClockingReports/update",
    },

    clientSatisfication:{
        get:environment.apiUrl+"/Clientsatifactions/getReport",
        delete:environment.apiUrl+"/Clientsatifactions/delete"
        },
        //Backfill
    Backfill:environment.apiUrl+"/backfill/backfill"
    },
  
    //dashboard
    sidemenu:environment.apiUrl+"/SideMenu/RoleBasedGetMenu",
    registerUser:environment.apiUrl+"/Users/Register",
    getCard:environment.apiUrl+"/Cards/getCard",
    getDesignation:environment.apiUrl+"/designations/get",
    //deployment
    deployment:{
        get:environment.apiUrl+"/Deployments/getReport",
        add:environment.apiUrl+"/Deployments/add",
        update:environment.apiUrl+"/Deployments/update"
    },
    ket:environment.apiUrl+"/api/Users/KET",
    //report
    report:
    {
            attendance:{
                  get:environment.apiUrl+"/attendances/getReport",
                  getbyid:environment.apiUrl+'/attendances/Getbyid',
                 update:environment.apiUrl+"/attendances/update",
                 delete:environment.apiUrl+"/attendances/delete"
                },
             afterActionReview:
             {
                get:environment.apiUrl+"/ActionReview/getReport",
                getbyid:environment.apiUrl+'/ActionReview/Getbyid',
                update:environment.apiUrl+"/ActionReview/update",
                delete:environment.apiUrl+"/ActionReview/delete"
            },
            dailyCheckLists:
            {
                get:environment.apiUrl+"/DailyCheckLists/get",
                getbyid:environment.apiUrl+'/DailyCheckLists/Getbyid',
                update:environment.apiUrl+"/DailyCheckLists/update",
                delete:environment.apiUrl+"/DailyCheckLists/delete"
            },
            fireIncident:
            {
                get:environment.apiUrl+"/FireIncident/get",
                getbyid:environment.apiUrl+'/FireIncident/Getbyid',
                update:environment.apiUrl+"/FireIncident/update",
                delete:environment.apiUrl+"/FireIncident/delete"

            },
            incidents:
            {
                add:environment.apiUrl+"/Incidents/add",
                get:environment.apiUrl+"/Incidents/get",
                getbyid:environment.apiUrl+'/Incidents/Getbyid',
                update:environment.apiUrl+"/Incidents/update",
                delete:environment.apiUrl+"/Incidents/delete"
            },
            jobappraisal:
            {
                get:environment.apiUrl+"/Jobappraisal/getReport",
                getbyid:environment.apiUrl+'/Jobappraisal/Getbyid',
                update:environment.apiUrl+"/Jobappraisal/update",
                delete:environment.apiUrl+"/Jobappraisal/delete"
            },
            foundItems:
            {
                get:environment.apiUrl+"/FoundItems/getReport",
                getbyid:environment.apiUrl+'/FoundItems/Getbyid',

            update:environment.apiUrl+"/FoundItems/update", 
                delete:environment.apiUrl+"/FoundItems/delete"
            },
            officerFeedback: 
            {
                get:environment.apiUrl+"/OfficerFeedback/get",
                getbyid:environment.apiUrl+'/OfficerFeedback/Getbyid',

                update:environment.apiUrl+"/OfficerFeedback/update",
                delete:environment.apiUrl+"/OfficerFeedback/delete"

            },
            jobTraining:
            {
                get:environment.apiUrl+"/JobTraining/getReport",
                getbyid:environment.apiUrl+'/JobTraining/Getbyid',
                update:environment.apiUrl+"/JobTraining/update",
                delete:environment.apiUrl+"/JobTraining/delete"
            },
            operationVisit:
            {
                get:environment.apiUrl+"/OperationVisit/getReport",
                getbyid:environment.apiUrl+'/OperationVisit/Getbyid',
                add:environment.apiUrl+"/OperationVisit/add",
                update:environment.apiUrl+"/OperationVisit/update",
                delete:environment.apiUrl+"/OperationVisit/delete"

            },
            performanceEvalution:
            {
                get:environment.apiUrl+"/PerformanceEvalution/getReport",
                getbyid:environment.apiUrl+'/PerformanceEvalution/Getbyid',

                update:environment.apiUrl+"/PerformanceEvalution/update",
                delete:environment.apiUrl+"/PerformanceEvalution/delete"
            },
            refresherTraining:
            {
                get:environment.apiUrl+"/RefresherTraining/getReport",
                getbyid:environment.apiUrl+'/RefresherTraining/Getbyid',
                update:environment.apiUrl+"/RefresherTraining/update",
                delete:environment.apiUrl+"/RefresherTraining/delete"
            },
            SupervisorChecklist:
            {
                get:environment.apiUrl+"/SupervisorChecklist/getReport",
                add:environment.apiUrl+"/SupervisorChecklist/add",
                getbyid:environment.apiUrl+'/SupervisorChecklist/Getbyid',

                update:environment.apiUrl+"/SupervisorChecklist/update",
                delete:environment.apiUrl+"/SupervisorChecklist/delete"
            },
            Sitevisits:
            {
                get:environment.apiUrl+"/Sitevisits/getReport",
                getbyid:environment.apiUrl+'/Sitevisits/Getbyid',

                update:environment.apiUrl+"/Sitevisits/update",
                delete:environment.apiUrl+"/Sitevisits/delete"

            }
            
    },
    //HRBay
    hrbay:{
            VacationReport:{
                get:environment.apiUrl+"/Vacations/getReport",
                getById:environment.apiUrl+"/Vacations/getById",
                update:environment.apiUrl+"/Vacations/update",
                delete:environment.apiUrl+"/Vacations/delete"
            },
            PublicHoliday:{
                get:environment.apiUrl+"/holidays/lookup",
                update:environment.apiUrl+"/holidays/update",
                add:environment.apiUrl+"/holidays/add",
                delete:environment.apiUrl+"/holidays/delete"
            
            },
            gradeMap:{
                get:environment.apiUrl+"/Grades/Lookup",
                add:environment.apiUrl+"/Grades/add",
                delete:environment.apiUrl+"/Grades/delete",
                update:environment.apiUrl+"/Grades/update",

            },
            vacationBalance:{
                add:environment.apiUrl+"/VacationBalance/add",
                reset:environment.apiUrl+"/VacationBalance/reset",
                getAllReport:environment.apiUrl+"/VacationBalance/get",
                getByUserId:environment.apiUrl+"/VacationBalance/getByUserId",
                updateBalanceReport:environment.apiUrl+'/VacationBalance/update'
            },
            payslip:{
                get:environment.apiUrl+"/Payslip/Report"
            },
            summary:{
                get:environment.apiUrl+"/Notification/Overtime/report"
            },
            elearning:{
                getbyid:environment.apiUrl+"/E_Learning/Getbyid",
                add:environment.apiUrl+"/E_Learning/add",
                get:environment.apiUrl+"/E_Learning/geteLearningReport",
                update:environment.apiUrl+"/E_Learning/update",
                delete:environment.apiUrl+"/E_Learning/delete"
            },
            elearningResponse:{
                getbyid:environment.apiUrl+"/elearningresponses/Getbyid",
                add:environment.apiUrl+"/elearningresponses/add",
                get:environment.apiUrl+"/elearningresponses/getelearningresponsesReport",
                update:environment.apiUrl+"/elearningresponses/update",
                delete:environment.apiUrl+"/elearningresponses/delete"
            },
            training:{
                add:environment.apiUrl+"/Training/add",
                get:environment.apiUrl+"/Training/gettrainingReport",
                delete:environment.apiUrl+"/Training/delete",
                update:environment.apiUrl+"/Training/update",
                getbyid:environment.apiUrl+"/Training/Getbyid"
            },
            salary:{
                add:environment.apiUrl+"/SalaryStructure/add",
                update:environment.apiUrl+"/SalaryStructure/update"
            },
            overtime:environment.apiUrl+"/OverTimes/Getbyid"
    }
    



}
