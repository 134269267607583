import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastService } from '../../service';

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss'],
})
export class ReportHeaderComponent implements OnInit {
  fromDate:string=new Date().toISOString();
  toDate:string=new Date().toISOString();
  siteId:string='';
  userId:string='';
  HEADER:string='';
  subject:string='';
  siteName:string='All Sites';
  userName:string='All Employees';
  params:any={};
  params2:any={};
  id1:string;
  id2:string;
  id3:string;
  id4:string;
  id5:string
  @Input() compare:boolean;
  @Input() compare2:boolean;
  @Input() site:boolean;
  @Input() searchDate:boolean;
  @Input() user:boolean;
  @Input() time:boolean;
  @Input() add:boolean;
  @Output() reportValue = new EventEmitter();
  constructor(private modalController: ModalController,private toast:ToastService) { }

  ngOnInit() {
    this.id1= this.randomString(10) 
    this.id2= this.randomString(10) 
    this.id3=this.randomString(10) 
    this.id4=this.randomString(10) 
    this.id5=this.randomString(10) 
    this.emitData();
  }

  async getSiteID(){
    const {SiteSearchModalComponent}=await import('../site-search-modal/site-search-modal.component');
    const modal=await this.modalController.create({
      component:SiteSearchModalComponent,
      cssClass:'small-modal',
      componentProps:{report:true}
    }); 

    modal.present();
    modal.onDidDismiss().then(res=>{
      if(res.data)
      {
        //console.log(res.data);
        this.siteName=res.data?.siteName;
        this.siteId=res.data?.id;
      }
    })
  }


  async getUserId(){
    const {UserSearchModalComponent }=await import('../user-search-modal/user-search-modal.component');
    const modal = await this.modalController.create({
    component: UserSearchModalComponent,
    cssClass:'small-modal',
    componentProps:{report:true}
    });
    await modal.present();
    modal.onDidDismiss().then(a=>{
      //console.log(a);
      if(a?.data)
      {
      this.userName=a.data?.firstName;
      this.userId=a.data?.id;
      }
    })
  }

  addfun()
  {
    this.params2.add=true;
    this.reportValue.emit(this.params2);
  }
  emitData()
  {
    this.ngAfterContentInit()
    if(this.compare)
    {
      this.params.fromDate=this.fromDate;
      this.params.toDate=this.toDate;
    }
    if(this.compare2)
    {
      this.params.fromDate=this.fromDate;
      this.params.toDate=this.toDate;
    }
    if(this.searchDate)
    {
      this.params.searchDate=this.fromDate;
    }
    if(this.user)
    {
      this.params.userId=this.userId;
      this.params.mentorId=this.userId;
      this.params.employeeId=this.userId;
      this.params.conductedBy=this.userId;
      if(!this.site)
      {
        this.HEADER=this.userName;
      }

    }
    if(this.site)
    {
      this.params.siteId=this.siteId;
      this.HEADER=this.siteName;
    }
    if(this.time)
    {
     
      this.params.subject=this.subject;
      
    }

    if(this.fromDate>this.toDate)
    {
      this.toast.presentToast('Alert','From date should either be equals or before To date','danger');
    }
    else{
      this.reportValue.emit(this.params);
    }
   
  }
  ngAfterContentInit() {
    
  }

  change(event){

  }
  randomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}

}
