import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { DataFactory } from '../datafactory/data-factory';
import { Server } from '../server/server';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class CommonModalService {

  allUserData:Array<any>=[];
  allSiteData:Array<any>=[];
  constructor(private dataFactory:DataFactory,private modalController: ModalController,private toastController: ToastService) {
  }
  public getAllUserName(){
    
   return  this.dataFactory.getMethod(Server.getAllUserName).pipe(map((res:any)=>res.data));
  }
  public getSiteDetails(){
    
    return this.dataFactory.getMethod(Server.site.getSiteDetails).pipe(map((res:any)=>res.data));
  }
  public AutoGenerate(value)
  {
    return this.dataFactory.postMethod(Server.autogenerate+'?nationality='+value).pipe(map((res:any)=>res?.data));
  }
  getSite(value){
    let a=this.allSiteData.find(res=>res.id==value);
    return  a?.siteName?a?.siteName:'N/A';
  }
  getUserName(value){
    let a=this.allUserData.find(res=>res.id==value);
    return   (a?.userName!=undefined)?(a?.firstName+' '+a?.lastName+' - '+a?.userName):'N/A';
    }

  getGradeDetails(){
    return this.dataFactory.getMethod(Server.getGradeDetails).pipe(map((res:any)=>res.data),shareReplay(1));
  }
  getSiteAllDetails(id){
    return this.dataFactory.postMethod(Server.site.getSiteAllDetails+'?Id='+id).pipe(map((res:any)=>res.data));
  }
  searchSite(value){
    return this.dataFactory.postMethod(Server.site.searchWithSiteName+'?name='+value).pipe(map((res:any)=>res?.data));
  }

  searchUser(value){
    return this.dataFactory.postMethod(Server.searchWithUserName+'?name='+value).pipe(map((res:any)=>res.data));
  }
  getImage(id)
  {
    return this.dataFactory.getMethod(Server.getImage+"?_id="+id).pipe(map((res:any)=>res.data));
  }
  async fullScreeView(src)
  {
    const {FullImageViewComponent}=await import("../sharedComponent/full-image-view/full-image-view.component")
    let modal=await this.modalController.create({
      component:FullImageViewComponent,
      componentProps:{imgSrc:src},
      cssClass:'small-modal'
    });
    modal.present();
  }
  uploadPic($event) : void {
    let inputValue=$event.target;  
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();
    let fileType=file.name.split('.').pop();
    if(fileType=='jpg'||fileType=='png')
    {
      myReader.onloadend = (e) => {
        //console.log(myReader.result);
      }
      myReader.readAsDataURL(file);
  
    }
    else
    {
      this.toastController.presentToast('Alert','Please pick png/jpg format image and try again','danger');
    }
  }
   uploadFile($event){
    let dataSource={
      "dateCreated": new Date().toISOString(),
      "fileName": "",
      "filePath": "",
      "source": "",
      "__v": 0
    }
    let inputValue=$event.target;  
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();
    let fileType=file.name.split('.').pop();
    if(fileType=='pdf'||fileType=='xlsx')
    {
      dataSource.fileName=file.name
      myReader.onloadend = (e) => {
        dataSource.source=""+myReader.result
      }
      myReader.readAsDataURL(file);
        return dataSource;


    }
    else
    {
      $event.target.value = null;
      this.toastController.presentToast('Alert','Please pick pdf or xlsx format image and try again','danger');
      return null

    }
  }
  public downloadKetFile(params){
    this.dataFactory.postMethod(Server.ket,params).subscribe((res:any)=>{

        //console.log(res.data)
        const linkSource = res?.data[0]?.source;
        const downloadLink = document.createElement("a");
        const fileName = res?.data[0]?.fileName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

      // }
      // else
      // {
      //   this.toastController.presentToast("Oops ! ","sorry for inconvience required document not found in server","danger");
      // }
    });
  }

  public downloadFile(id){
    this.dataFactory.getMethod(Server.download+"?_id="+id).subscribe((res:any)=>{
      if(res?.data!=null)
      {
        //console.log(res.data)
        const linkSource = res?.data[0]?.source;
        const downloadLink = document.createElement("a");
        const fileName = res?.data[0]?.fileName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
         
      }
      else
      {
        this.toastController.presentToast("Oops ! ","sorry for inconvience required document not found in server","danger");
      }
    });
  }
}
