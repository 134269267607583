// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  overflow: hidden;
  background: black;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.close {
  position: absolute;
  right: 0px;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/core/sharedComponent/full-image-view/full-image-view.component.scss","webpack://./../../LSS%20Web%20UI/lsswebui/src/app/core/sharedComponent/full-image-view/full-image-view.component.scss"],"names":[],"mappings":"AACE;EACI,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;ACAN;;ADEE;EACE,kBAAA;EACA,UAAA;EACA,eAAA;ACCJ","sourcesContent":["\r\n  .image-container{\r\n      overflow: hidden;\r\n      background: black;\r\n      display: flex;\r\n      justify-content: center;\r\n      height: 100vh;\r\n  }\r\n  .close{\r\n    position: absolute; \r\n    right: 0px;\r\n    font-size: 1rem;\r\n  }",".image-container {\n  overflow: hidden;\n  background: black;\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n}\n\n.close {\n  position: absolute;\n  right: 0px;\n  font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
