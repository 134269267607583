import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EmployeeManangementService } from '../../../modules/employee-management/service/employee-manangement.service';
import { CommonModalService } from '../../service/common-modal.service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-site-search-modal',
  templateUrl: './site-search-modal.component.html',
  styleUrls: ['./site-search-modal.component.scss'],
})
export class SiteSearchModalComponent implements OnInit {
  @Input() report:boolean=false;
  @Input() alterValue:any=[];
  count:number=10;
  siteData:Array<any>=[];
  search:boolean;
     
  constructor(private modalController: ModalController,private commonService:CommonModalService) { 
  }
  ngOnInit() {
     this.getSiteDetails();
  }

  async closeModal(){
      await this.modalController.dismiss();
  }

  getSiteDetails(){
    this.commonService.getSiteDetails().subscribe(res=>{
      this.commonService.allSiteData=res;
      this.siteData=cloneDeep(this.commonService.allSiteData);
      this.siteData=this.siteData.sort((a,b) => (a.siteName > b.siteName) ? 1 : ((b.siteName > a.siteName) ? -1 : 0));

      if(this.report)
      {
        this.siteData.unshift({id:'',siteName:'All Site'})
      }

    });



  
  }

  onSearchChange(event){
    if(event?.detail?.value)
    {
      this.search=true;
      this.commonService.searchSite(event.detail.value).subscribe(res=>this.siteData=res);
    }
  }
  showDefaultBar(){
    this.search=false;
    this.siteData=cloneDeep(this.commonService.allSiteData);
  }

  sendSiteId(value){
    this.modalController.dismiss(value);
  }


}