import { Injectable } from '@angular/core';
import { Server } from '../../../core/server/server';
import { DataFactory } from '../../../core/datafactory/data-factory';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HRbayService {

  constructor(private datafactory:DataFactory) { }

  public getVacationReport(params){
    return this.datafactory.postMethod(Server.hrbay.VacationReport.get,params).pipe(map((res:any)=>res?.data!=null?res?.data:[]))
  }
  public deletevacation(id){
    return this.datafactory.deleteMethod(Server.hrbay.VacationReport.delete+"?Id="+id);
  }
  public updatevacation(params){
    return this.datafactory.putMethod(Server.hrbay.VacationReport.update,params);
  }
  public getById(id){
    return this.datafactory.getMethod(Server.hrbay.VacationReport.getById+'?_id='+id).pipe(map((res:any)=>res?.data!=null?res?.data:[]));
  }
  
  /**
   * Holiday List
   */
  public getHolidaylist(params){
    return this.datafactory.postMethod(Server.hrbay.PublicHoliday.get,params).pipe(map((res:any)=>res?.data!=null?res?.data:[]))
  }
  public addHolidayList(params){
    return this.datafactory.postMethod(Server.hrbay.PublicHoliday.add,params);
  }
  public updateHolidayList(params){
    return this.datafactory.putMethod(Server.hrbay.PublicHoliday.update,params);
  }
  public deleteHolidayList(id){
    return this.datafactory.deleteMethod(Server.hrbay.PublicHoliday.delete+"?Id="+id);
  }
  /**
   * GradeMap
   */
  public getGradeMap() {
    return this.datafactory.getMethod(Server.hrbay.gradeMap.get).pipe(map((res:any)=>res?.data!=null?res?.data:[]));
  }
  public addGradeMap(params){
    return this.datafactory.postMethod(Server.hrbay.gradeMap.add,params)
  }
  public updateGradeMap(params){
    return this.datafactory.putMethod(Server.hrbay.gradeMap.update,params)
  }
  public deleteGradeMap(id){
    return this.datafactory.deleteMethod(Server.hrbay.gradeMap.delete+'?Id='+id)
  }

    /**
   * salary report
   */
  public getPayslip(date) {
    return this.datafactory.getMethod(Server.hrbay.payslip.get+"?payslipDate="+date).pipe(map((res:any)=>res?.data!=null?res?.data:[]))
  }
    /**
   * summary report
   */
  public getApprovedOvertime(params){
    return this.datafactory.postMethod(Server.hrbay.summary.get,params).pipe(map((res:any)=>res?.data!=null?res?.data:[]))
  }
    /**
   * salary details
   */

  public getSalaryDetail(id){
    return this.datafactory.getMethod(Server.getUserSalaryStructure+'?_id='+id).pipe(map((res:any)=>res?.data!=null?res?.data:[]));
  }
  public addSalaryDetails(params){
    return this.datafactory.postMethod(Server.hrbay.salary.add,params)
  }
  public UpdateSalaryDetails(params){
    return this.datafactory.putMethod(Server.hrbay.salary.update,params)
  }
    
  /**
   * vacation balance
   */ 
  public addVacationBalance(params){
    return this.datafactory.postMethod(Server.hrbay.vacationBalance.add,params)
  }
  public getAllVacationBalance(){
    return this.datafactory.getMethod(Server.hrbay.vacationBalance.getAllReport).pipe(map((res:any)=>res?.data!=null?res?.data:[]));
  }
  public getVacationBalanceByUserId(id){
    return this.datafactory.getMethod(Server.hrbay.vacationBalance.getByUserId+"?_id="+id).pipe(map((res:any)=>res?.data!=null?res?.data:[]));
  }
  public updateVacationBalance(params){
    return this.datafactory.putMethod(Server.hrbay.vacationBalance.updateBalanceReport,params);
  }
  public refreshVacationBalance(id){
    // alert('API Pending')
    return this.datafactory.getMethod(Server.hrbay.vacationBalance.reset+"?_id="+id);
  }
 
  
  public getLeaveType(){
    return this.datafactory.getMethod(Server.getVacationType).pipe(map((res:any)=>res?.data!=null?res?.data:[]));
  }
  /**
   * elearning
   */ 
  public addelearning(params){
    return this.datafactory.postMethod(Server.hrbay.elearning.add,params);
  }
  public getelearning(params){
    return this.datafactory.postMethod(Server.hrbay.elearning.get,params).pipe(map((res:any)=>res?.data!=null?res?.data:[]))
  }
  public updateElearningReport(params){
    return this.datafactory.putMethod(Server.hrbay.elearning.update,params);
  }
  public deleteElearningReport(id){
    return this.datafactory.deleteMethod(Server.hrbay.elearning.delete+'?Id='+id)
  }
  public getelearningById(id){
    return this.datafactory.getMethod(Server.hrbay.elearning.getbyid+'?_id='+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  /**
   * elearningResponse
   */ 
  public getelearningResById(id){
    return this.datafactory.getMethod(Server.hrbay.elearningResponse.getbyid+'?_id='+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public addelearningResponse(params){
    return this.datafactory.postMethod(Server.hrbay.elearningResponse.add,params);
  }
  public getelearningResponse(params){
    return this.datafactory.postMethod(Server.hrbay.elearningResponse.get,params).pipe(map((res:any)=>res?.data!=null?res?.data:[]))
  }
  public updateElearningResponseReport(params){
    return this.datafactory.putMethod(Server.hrbay.elearningResponse.update,params);
  }
   /**
   * training
   */ 
   public getTrainingById(id){
    return this.datafactory.getMethod(Server.hrbay.training.getbyid+'?_id='+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
   public addTraining(params){
    return this.datafactory.postMethod(Server.hrbay.training.add,params);
  }
  public gettraining(params){
    return this.datafactory.postMethod(Server.hrbay.training.get,params).pipe(map((res:any)=>res?.data!=null?res?.data:[]))
  }
  public updateTrainingReport(params){
    return this.datafactory.putMethod(Server.hrbay.training.update,params);
  }
  public deletetrainingReport(id){
    return this.datafactory.deleteMethod(Server.hrbay.training.delete+'?Id='+id)
  }
  
}

  