// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  width: 100%;
  align-items: center;
}
:host .type-toggle {
  padding-inline-start: 0.5rem;
}
:host .type-toggle .show-option,
:host .type-toggle .hide-option {
  font-size: 1.2rem;
  display: block;
}
:host .type-toggle .show-option:not(ion-icon),
:host .type-toggle .hide-option:not(ion-icon) {
  text-transform: uppercase;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/core/sharedComponent/show-hide-password/show-hide-password.component.scss","webpack://./../../LSS%20Web%20UI/lsswebui/src/app/core/sharedComponent/show-hide-password/show-hide-password.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;ACCF;ADCE;EACE,4BAAA;ACCJ;ADCI;;EAEE,iBAAA;EACA,cAAA;ACCN;ADEM;;EACE,yBAAA;EACA,eAAA;ACCR","sourcesContent":[":host {\r\n  display: flex;\r\n  width: 100%;\r\n  align-items: center;\r\n\r\n  .type-toggle {\r\n    padding-inline-start: 0.5rem;\r\n\r\n    .show-option,\r\n    .hide-option {\r\n      font-size: 1.2rem;\r\n      display: block;\r\n\r\n      // In case you want to use text instead of icons\r\n      &:not(ion-icon) {\r\n        text-transform: uppercase;\r\n        font-size: 1rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n",":host {\n  display: flex;\n  width: 100%;\n  align-items: center;\n}\n:host .type-toggle {\n  padding-inline-start: 0.5rem;\n}\n:host .type-toggle .show-option,\n:host .type-toggle .hide-option {\n  font-size: 1.2rem;\n  display: block;\n}\n:host .type-toggle .show-option:not(ion-icon),\n:host .type-toggle .hide-option:not(ion-icon) {\n  text-transform: uppercase;\n  font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
