import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  
})
export class AppComponent implements OnInit {
  menu:boolean;
  constructor(public auth:AuthService) {
  }
  ngOnInit(){
    
  }
  receive(p)
  {
    Promise.resolve().then(res=>this.menu=p);
  }
}
