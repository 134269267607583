import { NgModule, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material/material.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { httpInterceptor } from './core/interceptors/httpInterceptors';
import { QuicklinkModule } from 'ngx-quicklink';
import { ScrollbarThemeModule } from './core/directives/ScrollbarThemeModule';
import { sharedComponentModule } from './core/sharedComponent/sharedComponent.module';
import { DashboardResolverService } from './modules/dashboard/resolver/dashboard-resolver.service';
import { ByPassSecurityPipe } from './core/pipe/by-pass-security.pipe';
import { VacationbalanceResolverService } from './modules/hrbay/component/vacation-balance/vacationbalance-resolver.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DocumentationComponent } from './modules/organization/component/documentation/documentation.component';
import { NotificationModalComponent } from './modules/notification-modal/notification-modal.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import{OverTimePage}from'./modules/over-time/over-time.page';

@NgModule({
    declarations: [AppComponent, DocumentationComponent, NotificationModalComponent, ByPassSecurityPipe, OverTimePage],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, BrowserAnimationsModule,
        MaterialModule, HttpClientModule, FormsModule,
        ScrollbarThemeModule,
        sharedComponentModule,
        ScrollbarThemeModule,
        PdfViewerModule,
        QuicklinkModule
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: httpInterceptor,
            multi: true
        },
        DashboardResolverService,
        VacationbalanceResolverService,
        HttpClient
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
