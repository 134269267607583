import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertController: AlertController) { }

  async presentAlertConfirm(header:string,message:string,OkHandler?:any,Ok?:string,CancelHandler?:any,Cancel?:any) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      cssClass:'my-alert-class',
      buttons: [
        {
          text: Cancel?Cancel:'Cancel',
          role: 'cancel',
          handler: CancelHandler
        }, {
          text: Ok?Ok:'Ok',
          handler:OkHandler
        }
      ]
    });
  
    await alert.present();
  }

  async presentAlert(header:string,message:string,OkHandler?:any,Ok?:string,) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      cssClass:'my-alert-class',
      buttons: [
         {
        text: Ok?Ok:'Ok',
        handler:OkHandler
      }]
    });
  
    await alert.present();
  }
}
