import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { filter, map } from 'rxjs/operators';
import { AuthService,CommonModalService,EventService, ToastService } from '../../service';
import { SideMenuService } from './service/side-menu.service';
import { PopoverComponent } from 'src/app/core/sharedComponent/popover/popover.component';
import { NotificationModalComponent } from 'src/app/modules/notification-modal/notification-modal.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @Output() expandMenu = new EventEmitter();
  expanded:boolean=true;
  toggle:number;
  userRole:string;
  header: string;
  authorized:boolean;
  active:string;
  userName: string;
  subHeader: string;
  profilePic:string='assets/default profile/default profile.png';
  notification:Array<any>=[];
  notificationCount:number=0;
  constructor(public router:Router,public sideMenuService:SideMenuService,
    private http:HttpClient,
    public auth:AuthService,private commonService:CommonModalService, 
    private modalController:ModalController,
    private toastService:ToastService,
    private eventService:EventService,private popoverController: PopoverController) {
    if(this.auth.getToken())
    {
    this.userRole=this.auth.getDecodedDetails()?.typ;
      this.sideMenuService.getSideMenu(this.userRole);
      this.authorized=true;
      this.commonService.getAllUserName().subscribe(res=>{
        this.commonService.allUserData=res;
      });
      this.commonService.getSiteDetails();
      this.sideMenuService.getUserProfilePic(this.auth.getDecodedDetails()?.unique_name);
    }
    else
    {
    this.eventService.subscribe('logged',(res)=>{
      this.userRole=this.auth.getDecodedDetails()?.typ;
      this.sideMenuService.getSideMenu(this.userRole);
      this.authorized=true;
      this.router.navigateByUrl('/dashboard');
      this.eventService.unsubscribe('logged');
    });
    }
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.urlAfterRedirects)
    )
    .subscribe(url => {
      let temp=url.split('?')[0].split('/');
      this.active=temp[1];  
      this.active=this.active.replace('%20'," ");
      this.active=this.active.charAt(0).toUpperCase() + this.active.slice(1);
      this.header=url.split('?')[0].split('/').pop();
      this.subHeader='/'+this.header;
      //console.log(this.active)
      this.header=this.header.split('-').join(" ");   
    });

    this.getNotification();
    setInterval(()=>{
      if(this.auth.getDecodedDetails()?.nameid)
      {
        this.getNotification();
      }
    },180000)
  }
  ngOnInit(): void {
    this.userName=this.auth.getDecodedDetails().given_name;
    this.expandMenu.emit(this.expanded);
  }
  menutoggle(){
    this.expanded=!this.expanded;
    this.expandMenu.emit(this.expanded);
  }
  menuopen(){
    this.expanded=false;
    this.expandMenu.emit(false);
  }
  getNotification(){
    this.sideMenuService.getNotification(this.auth.getDecodedDetails()?.nameid).subscribe((res:any)=>{
     this.sideMenuService.notifications=res.val;
     this.notificationCount=this.sideMenuService.notifications.length;
     //console.log(res)
    })
  }
  route(x)
  {
    this.router.navigateByUrl(x);
  }
  async openNotification(){
    //const {NotificationModalComponent}=await import('../../../modules/notification-modal/notification-modal.component');
    let modal=await this.modalController.create({
      component:NotificationModalComponent,
      cssClass:'small-modal',
      componentProps:{notificationdetails:this.sideMenuService.notifications}
    })
    modal.present();
  }
  fullScreen(src){
    this.commonService.fullScreeView(src)
  }
  closeMenu()
  {
    this.expanded=true;
    this.expandMenu.emit(true);
    this.toggle=null
  }
  navigate(path,i)
  {
    if(path==null)
    {

      this.toggle==i?this.toggle=null:this.toggle=i;
      this.menuopen();
    }
      else
      {
        this.router.navigateByUrl("/"+path);
      }
  }

  async presentPopover(event) {
    //const {PopoverComponent}= await import('../popover/popover.component');
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      translucent: true,
      cssClass: "my_custom_class",
    });
    await popover.present();
    popover.onDidDismiss().then(res=>{
      if(res.data!=null)
      {
        this.sideMenuService.uploadUserProfile(res.data).subscribe((response:any)=>{
          if(response?.data)
          {
            this.sideMenuService.dp=res?.data?.profilePicture?.source;
            this.toastService.presentToast('Information',response.message,'success');
          }
          else
          {
            this.toastService.presentToast('Alert',response.message,'danger');

          }
        })
      }
    })

  }


}
