import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-full-image-view',
  templateUrl: './full-image-view.component.html',
  styleUrls: ['./full-image-view.component.scss'],
})
export class FullImageViewComponent implements OnInit,AfterViewInit {

  @Input('imgSrc') imgSrc:string;
  constructor(private modalController: ModalController) { }

  ngOnInit() {}
  scale = 100;
  top = 0;
  left = 0;

  @ViewChild("image", { static: false }) image: ElementRef<HTMLImageElement>;

  ngAfterViewInit(): void {
    fromEvent(window, "wheel").subscribe((ev: WheelEvent) => {
      const newScale = this.scale - ev.deltaY * 0.2;
      this.scale = Math.max(newScale, 100);
      if(ev.clientY>=0)
      this.top = ev.clientY - this.scale / 2;
      this.left = ev.clientX - this.scale / 2;
    });
  }
  
  @HostListener('mousemove', ['$event'])
  // @HostListener('click', ['$event'])
  mouseMove(event: MouseEvent) {
    this.top=event.clientY-500;
    this.left=event.clientX-500;
  }
  closeModal(){
    this.modalController.dismiss()
  }
}
