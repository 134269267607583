import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/AuthGuard/auth.guard';
import { LoginGuard } from './core/AuthGuard/login.guard';
import { QuicklinkStrategy } from "ngx-quicklink";
import { DashboardResolverService } from './modules/dashboard/resolver/dashboard-resolver.service';
import { DocumentationComponent } from './modules/organization/component/documentation/documentation.component';

const routes: Routes = [

  {
    path: 'Organization',
    loadChildren: () => import('./modules/organization/organization.module').then( m => m.OrganizationPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./modules/forgotpassword/forgotpassword.module').then( m =>m.ForgotpasswordPageModule)
  },
  {
    path: 'clientsatisfications',
    loadChildren:()=>import('./modules/clintsatisfication/clintsatisfication.module').then(m=>m.ClintsatisficationModule)
  },
  {
    path:'login',
    loadChildren:()=>import('./modules/login/login.module').then(m=>m.LoginModule),
    canActivate:[LoginGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/signup/signup.module').then( m => m.SignupPageModule),
    canActivate:[LoginGuard]
  
  },
  {
    path: 'Employee Management',
    loadChildren: () => import('./modules/employee-management/employee-management.module').then( m =>m.EmployeeManagementPageModule),
    canActivate:[AuthGuard]

  },
  {
    path:'documentation',
    component:DocumentationComponent

  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate:[AuthGuard],
    resolve:{details:DashboardResolverService}
  },
  {
    path: 'Reports',
    loadChildren: () => import('./modules/reports/reports.module').then( m => m.ReportsPageModule),
    canActivate:[AuthGuard]

  }
  ,
  {
    path: 'Deployment Chart',
    loadChildren: () => import('./modules/deployment-chart/deployment-chart.module').then( m => m.DeploymentChartPageModule),
    canActivate:[AuthGuard]

  },
  {
    path: 'Site Management',
    loadChildren: () => import('./modules/site-management/site-management.module').then( m => m.SiteManagementPageModule),
    canActivate:[AuthGuard]

  },
  {
    path: 'HR Bay',
    loadChildren: () => import('./modules/hrbay/hrbay.module').then( m => m.HRBayPageModule),
    canActivate:[AuthGuard]

  }
  ,{
    path:'**',
  redirectTo:'/dashboard'
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload',preloadingStrategy:QuicklinkStrategy})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
