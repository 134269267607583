export const tableHeader={
  attendance:['id','userId','siteId','startTime','endTime','action'],
  afterActionReview:['id','dateTime','Time','location','userId','action'],
  dailyCheckList:['id','userId','handOverTo','action'],
  fireIncident:['id','userId','incidentType','incidentDateTime','Time','cause','action'],
  Incident:['id','reportNo','userId','incidentDate','Time','subject','aarId','action'],
  jobAppraisal:['id','employeeId','appraisalDate','overallRating','action'],
  lostAndFound:['id','informant','dateTime','Time','location','action'],
  officerFeedback:['id','siteId','userId','dateCreated','Time','contactNo','comments','action'],
  operationalVisit:['id','conductedBy','visitDate','Time','overallPerformance','action'],
  performanceEvalution:['id','employeeId','evaluatorId','action'],
  jobTraining:['id','mentorId','traineeId','trainingDate','action'],
  refreshTraining:['id','conductedBy','trainingTopic','trainingDate','action'],
  Supervisor:['id','dateTime','Time','securitySupervisorId','action'],
  siteVisit:['id','timeIn','timeOut','visitedUserId','action'],
  occurrence:['id','userId','dateTime','Time','occurrence','subject','action'],
  vacation:['id','employeeId','approverId','status','dateCreated'], 
  overtime:['id','employeeId','userId','dateCreated','overTimeDuration'],
  vacationReport:['id','employeeId','approverId','status','dateCreated','leaveTypeCode','duration','action'],
  clientSatisfaction:['id','siteId','userId','dateCreated','Time','feedback','action'],
  salary:['id','employeeId','payslip'],
  summary:['id','userId','metaData','notifyUserId','dateCreated'],
  clocking:['id','points','planTime','clockingData','action'],
  elearning:['id','dateCreated','title','details','action'],
  training:['id','userId','dateCreated','fromDate','title','details','action'],
  elearningResponse:['id','userId','dateCreated','results','action']
}