import { Injectable } from '@angular/core';
import { Server } from '../../../core/server/server';
import { DataFactory } from '../../../core/datafactory/data-factory';
import { map } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { SideMenuService } from 'src/app/core/sharedComponent/navigation/service/side-menu.service';
import { ToastService } from 'src/app/core/service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private dataFactory:DataFactory,
    private toastService:ToastService,
    private sideMenService:SideMenuService,private modalController: ModalController) { }


  public async acknowledge(id){
   return await this.sideMenService.updateNotification(id).subscribe((res:any)=>{
      if(res?.data)
      {
        let i=this.sideMenService.notifications.findIndex(mes=>mes._id==id);
        
        this.sideMenService.notifications.splice(i,1)
          this.sideMenService.openNotification();
          this.toastService.presentToast('Information','Report has been successfully acknowledgd ','success');
      }
      else
       {
         this.toastService.presentToast('Information',res?.message,'danger');
       }

    })

  }
  //AfterActionReview
  public getAfterActionReview(params){
    return this.dataFactory.postMethod(Server.report.afterActionReview.get,params).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public getAfterActionGetById(id){
    return this.dataFactory.getMethod(Server.report.afterActionReview.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public DeleteAfterActionReviewReport(id){
    return this.dataFactory.deleteMethod(Server.report.afterActionReview.delete+"?Id="+id);
  }
  public updateAfterActionReviewReport(params){
    return this.dataFactory.putMethod(Server.report.afterActionReview.update,params);
  }

  //dailyChecklist

  public getDailyCheckList(params){
    return this.dataFactory.postMethod(Server.report.dailyCheckLists.get,params).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public getDailyCheckListById(id){
    return this.dataFactory.getMethod(Server.report.dailyCheckLists.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public DeleteDailyCheckList(id){
    return this.dataFactory.deleteMethod(Server.report.dailyCheckLists.delete+"?Id="+id)
  }  
  public updateDailyCheckList(params){
    return this.dataFactory.putMethod(Server.report.dailyCheckLists.update,params)
  }

  // fireIncident
  public getFireIncidentReport(params){
    return this.dataFactory.postMethod(Server.report.fireIncident.get,params).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public getFireIncidentReportById(id){
    return this.dataFactory.getMethod(Server.report.fireIncident.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public deleteFireIncidentReport(id){
    return this.dataFactory.deleteMethod(Server.report.fireIncident.delete+"?Id="+id)
  }
  public updateFireIncidentReport(params){
    return this.dataFactory.putMethod(Server.report.fireIncident.update,params)
  } 

  // incidents
  public getIncidentReport(params){
    return this.dataFactory.postMethod(Server.report.incidents.get,params).pipe(map((res:any)=>res.data.sort((a, b) => {
      return <any>new Date(a.incidentDate) - <any>new Date(b.incidentDate);
    })!=null?res.data:[]))
  }
  public getIncidentReportbyId(id){
    return this.dataFactory.getMethod(Server.report.incidents.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public addIncidentReport(params){
    return this.dataFactory.postMethod(Server.report.incidents.add,params)
  }

  public deleteIncidentReport(id){
    return this.dataFactory.deleteMethod(Server.report.incidents.delete+"?Id="+id)
  }
  public updateIncidentReport(params){
    return this.dataFactory.putMethod(Server.report.incidents.update,params)
  }

  // jobappraisal
  public getJobappraisalReport(params){
    return this.dataFactory.postMethod(Server.report.jobappraisal.get,params).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public getJobappraisalReportById(id){
    return this.dataFactory.getMethod(Server.report.jobappraisal.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public deleteJobappraisalReport(id){
    return this.dataFactory.deleteMethod(Server.report.jobappraisal.delete+"?Id="+id)
  }
  public updateJobappraisalReport(params){
    return this.dataFactory.putMethod(Server.report.jobappraisal.update,params)
  }
  // foundItems
  public getLostAndFoundReport(params){
    return this.dataFactory.postMethod(Server.report.foundItems.get,params).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public getLostAndFoundReportById(id){
    return this.dataFactory.getMethod(Server.report.foundItems.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }

  public deleteLostAndFoundReport(id){
    return this.dataFactory.deleteMethod(Server.report.foundItems.delete+"?Id="+id)
  }
  public updateLostAndFoundReport(params){
    return this.dataFactory.putMethod(Server.report.foundItems.update,params)
  }

  // officerFeedback
  public getOfficeFeedbackReport(params){
    return this.dataFactory.postMethod(Server.report.officerFeedback.get,params).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public getOfficeFeedbackReportById(id){
    return this.dataFactory.getMethod(Server.report.officerFeedback.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public updateOfficeFeedbackReport(params){
    return this.dataFactory.putMethod(Server.report.officerFeedback.update,params)
  }  
  public deleteOfficeFeedbackReport(id){
    return this.dataFactory.deleteMethod(Server.report.officerFeedback.delete+"?Id="+id)
  }

  // jobTraining
  public getJobTrainingReport(params){
    return this.dataFactory.postMethod(Server.report.jobTraining.get,params).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  
  public getJobTrainingReportBy(id){
    return this.dataFactory.getMethod(Server.report.jobTraining.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public deleteJobTrainingReport(id){
    return this.dataFactory.deleteMethod(Server.report.jobTraining.delete+"?Id="+id)
  }
  public updateJobTrainingReport(params){
    return this.dataFactory.putMethod(Server.report.jobTraining.update,params)
  }

  // performanceEvalution
  public getPerformanceEvalutionReport(params){
    return this.dataFactory.postMethod(Server.report.performanceEvalution.get,params).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  
  public getPerformanceEvalutionReportById(id){
    return this.dataFactory.getMethod(Server.report.performanceEvalution.getbyid+'?_id='+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public deletePerformanceEvalutionReport(id){
    return this.dataFactory.deleteMethod(Server.report.performanceEvalution.delete+"?Id="+id)
  }
  public updatePerformanceEvalutionReport(params){
    return this.dataFactory.putMethod(Server.report.performanceEvalution.update,params)
  }
  // refresherTraining
  public getRefresherTrainingReport(params){
    return this.dataFactory.postMethod(Server.report.refresherTraining.get,params).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public getRefresherTrainingReportById(id){
    return this.dataFactory.getMethod(Server.report.refresherTraining.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }

  public deleteRefresherTrainingReport(id){
    return this.dataFactory.deleteMethod(Server.report.refresherTraining.delete+"?Id="+id)
  }
  public updateRefresherTrainingReport(params){
    return this.dataFactory.putMethod(Server.report.refresherTraining.update,params)
  }

  // SupervisorChecklist
  public addSupervisorChecklistReport(params){
    return this.dataFactory.postMethod(Server.report.SupervisorChecklist.add,params)
  }
  public getSupervisorChecklistReport(params){
    return this.dataFactory.postMethod(Server.report.SupervisorChecklist.get,params).pipe(map((res:any)=>res.data.sort((a, b) => {
      return <any>new Date(a.dateTime) - <any>new Date(b.dateTime);
    })!=null?res.data:[]))
  }
  public getSupervisorChecklistReportById(id){
    return this.dataFactory.getMethod(Server.report.SupervisorChecklist.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }

  public deleteSupervisorChecklistReport(id){
    return this.dataFactory.deleteMethod(Server.report.SupervisorChecklist.delete+"?Id="+id)
  }
  public updateSupervisorChecklistReport(params){
    return this.dataFactory.putMethod(Server.report.SupervisorChecklist.update,params)
  }

  // Sitevisits
  public getSitevisitsReport(params){
    return this.dataFactory.postMethod(Server.report.Sitevisits.get,params).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  
  public getSitevisitsReportById(id){
    return this.dataFactory.getMethod(Server.report.Sitevisits.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  public deleteSitevisitsReport(id){
    return this.dataFactory.deleteMethod(Server.report.Sitevisits.delete+"?Id="+id)
  }
  public updateSitevisitsReport(params){
    return this.dataFactory.putMethod(Server.report.Sitevisits.update,params)
  }

  // operationVisit
  public getOperationVisitReport(params){
    return this.dataFactory.postMethod(Server.report.operationVisit.get,params).pipe(map((res:any)=>res.data.sort((a, b) => {
      return <any>new Date(a.visitDate) - <any>new Date(b.visitDate);
    })!=null?res.data:[]))
  }
  public addOperationVisitReport(params){
    return this.dataFactory.postMethod(Server.report.operationVisit.add,params);
  }
  public getOperationVisitReportById(id){
    return this.dataFactory.getMethod(Server.report.operationVisit.getbyid+"?_id="+id).pipe(map((res:any)=>res.data!=null?res.data:[]))
  }
  
  public deleteOperationVisitReport(id){
    return this.dataFactory.deleteMethod(Server.report.operationVisit.delete+"?Id="+id)
  }
  public updateOperationVisitReport(params){
    return this.dataFactory.putMethod(Server.report.operationVisit.update,params)
  }

  

}
