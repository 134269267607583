import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Server } from '../../../core/server/server';
import { AuthService } from '../../../core/service';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http:HttpClient,private auth:AuthService) { }

  getCardDetails(){
   return this.http.get(Server.getCard+"?Role="+this.auth.getDecodedDetails()?.typ);
  }
}
