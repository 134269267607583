import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonModalService } from '../../service/common-modal.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-user-search-modal',
  templateUrl: './user-search-modal.component.html',
  styleUrls: ['./user-search-modal.component.scss'],
})
export class UserSearchModalComponent implements OnInit {
  @Input() report:boolean;
  @Input() alterValue:Array<any>=[];
  count:number=10;
  userData:Array<any>=[];
  search:boolean;

  constructor(private modalController: ModalController,private commonService:CommonModalService) { 
  }
  ngOnInit() {
  if(this.alterValue.length==0)
  this.getUserDetails();
  else 
  this.userData=this.alterValue
  //console.log(this.userData);
}

  async closeModal(){
      await this.modalController.dismiss();
  }
  getUserDetails(){

    
    this.commonService.getAllUserName().subscribe(res=>{
      this.commonService.allUserData=res
      this.userData=cloneDeep(this.commonService.allUserData);
      this.userData=this.userData.filter(item => !item.userName.includes('_'));
      this.userData=this.userData.sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));

      if(this.report)
      {
        this.userData.unshift({firstName: 'All Employees',id: '',lastName: '',userName: ''})
      }
    });


  }
  onSearchChange(event){
    if(event?.detail?.value)
    {
      this.search=true;
              this.commonService.searchUser(event.detail.value).subscribe(res=>this.userData=res);
      }
    }
    
  showDefaultBar(){
    this.userData=cloneDeep(this.commonService.allUserData)
  }

  sendUserName(value){
    this.modalController.dismiss(value);
  }


}
