import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { LogoutService } from '../../../modules/logout/logout.service';
import { AuthService, ToastService } from '../../service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  constructor(private log:LogoutService,private authService:AuthService,
    private navController:NavController,
    private popoverController: PopoverController,private toastService:ToastService) { }

  ngOnInit() {}

  close(){
    this.popoverController.dismiss();
  }
  logout(){
    this.popoverController.dismiss();
    this.log.loggingOut();
  }
  profile(){
    this.close();
    this.navController.navigateForward('/Employee Management/employee-dashboard',{state:{item:this.authService.getDecodedDetails()?.unique_name}})
  }
  changeListener($event) : void {
    let dataSource={
      "id": this.authService.getDecodedDetails()?.nameid,
      "profilePicture": {
        "dateCreated": new Date().toISOString(),
        "fileName": "",
        "filePath": "",
        "source": "",
        "__v": 0
      } 
    
    }
    let inputValue=$event.target;  
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();
    let fileType=file.name.split('.').pop();
    if(fileType=='jpg'||fileType=='png')
    {
      dataSource.profilePicture.fileName=file.name;
      myReader.onloadend = (e) => {
        dataSource.profilePicture.source=""+myReader.result;
        //console.log(dataSource)
        this.popoverController.dismiss(dataSource);
      }
      myReader.readAsDataURL(file);
  
    }
    else
    {
      this.toastService.presentToast('Alert','Please pick png/jpg format image and try again','danger');
      this.popoverController.dismiss();
    }
  }


}
