import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { pdfFeild } from '../../config/ReportPdfFeildName';
import { CommonModalService } from './common-modal.service';
import { LoaderService } from './loader.service'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
@Injectable({
  providedIn: 'root'
})
export class TablePdfService {

  constructor(private DatePipe:DatePipe,private commonService:CommonModalService,private loader:LoaderService) { }

  headerSplit(value){
    let a= value.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })
    return  a;
  }
  private getSite(value){
    return  this.commonService.getSite(value);
  }
  private getUserName(value){
    return   this.commonService.getUserName(value);
  }
  getPDfTable(header:string,recordData:Array<any>){

    let doc = new jsPDF();
    var data = [];
  
    let companyDetails:any=JSON.parse(atob(localStorage.getItem('companyDetails')));
  
  doc.addImage(companyDetails.logo, 'JPEG', 10, 5, 20, 20).setTextColor(200,20,10).setFontSize(25).text(companyDetails.name,35,15,).setTextColor(100).setFontSize(14).text(companyDetails.address,35,25).line(10, 30, 200, 30).setFontSize(20).text(this.headerSplit(header)+" Report",((doc.internal.pageSize.width /2)-(header+" Report").length)-20,45,)
  doc.setFontSize(14);
  //console.log(pdfFeild[header])
  var dbObject:Array<string>=pdfFeild[header].map(res=>Object.keys(res).join());
  let feildName:Array<string>=pdfFeild[header].map(res=>Object.values(res).join())
  recordData.forEach(res=>{
    let m=[]
    dbObject.forEach((key,i)=>{
      if(key.indexOf('date')>=0 ||key.indexOf('Date')>0||key.indexOf('Time')>0||key.indexOf('time')>0 )
      {  
            if(feildName[i]=='Date')
            {
              m.push(this.DatePipe.transform(res[key],'dd/MM/yyyy'));
            }
            else{
              m.push(this.DatePipe.transform(res[key],'dd/MM/yyyy HH:mm'));
            }
      }
      else if(key=='siteId')
      {
        m.push(this.getSite(res[key]));
      }
      else if(key.indexOf('Id')>=0 ||key.indexOf('Staff')>=0||key.indexOf('handOverTo')>=0||key.indexOf('By')>=0)
      {
        m.push(this.getUserName(res[key]))
      }
      else
      {
        m.push(res[key]?res[key]:'N/A')
      }
    })
    data.push(m);
  })
  var columns = feildName;

  (doc as any).autoTable(columns, data,{
    theme : 'grid',
    styles: {
      fontSize: 12
    },
    startY: 50

    });

  this.loader.hide();
  doc.save(header+'_Report_Export_'+this.DatePipe.transform(new Date(),"dd/MM/yyyy HH:mm")+'.pdf');
  
    }
  
}
