// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  font-style: italic !important;
}

@media screen and (max-width: 900px) {
  .MobResCal {
    width: 100% !important;
    align-items: auto;
  }
  .MobResCal1 {
    width: 100% !important;
  }
}
.addbtn {
  width: 65px;
  height: 40px;
  float: left;
}`, "",{"version":3,"sources":["webpack://./src/app/core/sharedComponent/report-header/report-header.component.scss","webpack://./../../LSS%20Web%20UI/lsswebui/src/app/core/sharedComponent/report-header/report-header.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;ACCJ;;ADCA;EACI;IACE,sBAAA;IACA,iBAAA;ECEJ;EDAE;IACG,sBAAA;ECEL;AACF;ADAA;EAEE,WAAA;EACA,YAAA;EACA,WAAA;ACCF","sourcesContent":["ion-label{\r\n    font-style: italic!important;\r\n}\r\n@media screen and (max-width: 900px) {\r\n    .MobResCal {\r\n      width: 100% !important;\r\n      align-items: auto;\r\n    }\r\n    .MobResCal1{\r\n       width: 100% !important;\r\n    }\r\n  }\r\n.addbtn\r\n{\r\n  width: 65px;\r\n  height: 40px;\r\n  float: left;\r\n}\r\n","ion-label {\n  font-style: italic !important;\n}\n\n@media screen and (max-width: 900px) {\n  .MobResCal {\n    width: 100% !important;\n    align-items: auto;\n  }\n  .MobResCal1 {\n    width: 100% !important;\n  }\n}\n.addbtn {\n  width: 65px;\n  height: 40px;\n  float: left;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
