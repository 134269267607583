import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { pdfFeild } from 'src/app/config/ReportPdfFeildName';
import { DatePipe } from '@angular/common';
import { CommonModalService } from './common-modal.service';
import { LoaderService } from './loader.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelGenerateService {
 
  constructor(private DatePipe:DatePipe,private commonService:CommonModalService,private loader:LoaderService) { }

  getSite(value){
    return  this.commonService.getSite(value);
  }
  getUserName(value){
    return   this.commonService.getUserName(value);
  }

  generateDocumentExcel(header: string,recordData: any[] ): void {
    this.loader.show();
    var json=[];    
    var dbObject:Array<string>=pdfFeild[header].map(res=>Object.keys(res).join());
    var feildName:Array<string>=pdfFeild[header].map(res=>Object.values(res).join())
    recordData.forEach((res,index)=>{
      json.push({"":"S.no"," ":""+(index+1)})
      dbObject.forEach((key,i)=>{
                      if(key.indexOf('date')>=0 ||key.indexOf('Date')>0||key.indexOf('Time')>0||key.indexOf('time')>0 )
                      {  
                        if(key=='planTime')
                        {
                          json.push({"":feildName[i]," ":res[key]});

                        }
                        else
                        {
                            if(feildName[i]=='Date')
                        {                          
                          json.push({"":feildName[i]," ":this.DatePipe.transform(res[key],'dd/MM/yyyy - hh:mm a')})
                         }
                         else{
                          json.push({"":feildName[i]," ":this.DatePipe.transform(res[key],'dd/MM/yyyy HH:mm')})

                        }
                      }

                       }
                       else if(key=='siteId')
                       {
                        json.push({"":feildName[i]," ":this.getSite(res[key])});
                       }
                       else if(key.indexOf('Id')>=0 ||key.indexOf('Staff')>=0||key.indexOf('handOverTo')>=0||key.indexOf('By')>=0)
                       {
                        json.push({"":feildName[i]," ":this.getUserName(res[key])});

                       }    
                       else if(key.indexOf('DetailsBreak')>=0){
                        json.push({"":feildName[i]});
                        let details=res[key.slice(0,key.indexOf('DetailsBreak'))];
                        let data=details.data;
                        if(header=='clocking')
                        {
                          data=details;
                        }

                        switch(header)
                        {
                          case 'dailyCheckList':{
                                  for (var i = 0; i < data.length; i++) {                
                                    json.push({"":data[i].question," ":data[i].quantity});
                                  }
                            break;
                           }
                           case 'fireIncident':{
                                      for (var i = 0; i < data.length; i++) {                
                                        json.push({"":data[i].question," ":data[i].response});
                                  }
                            break;
                           }
                           case 'jobAppraisal':{
                                      for (var i = 0; i < data.length; i++) {                
                                        json.push({"":data[i].item," ":data[i].selection});
                                  }

                            break;
                           }
                           case 'jobTraining':{
                                      for (var i = 0; i < data.length; i++) {                
                                        json.push({"":data[i].competency," ":data[i].progress});
                                  }

                            break;
                           }
                           case 'operationalVisit':{
                                      for (var i = 0; i < data.length; i++) {                
                                        json.push({"":data[i].criteria," ":(key.slice(0,key.indexOf('DetailsBreak'))=='officerDuties'?data[i].response:data[i].point)});
                                  }

                            break;

                           }
                           case 'performanceEvalution':{
                                      for (var i = 0; i < data.length; i++) {                
                                        json.push({"":data[i].criteria," ":data[i].selection});
                                  }

                            break;
                           }
                           case 'Supervisor':{
                            for (var i = 0; i < data.length; i++) {                
                              json.push({"":data[i].question," ":data[i].isSelected});
                        }

                  break;
                 }                           case 'clocking':{
                  for (var i = 0; i < data.length; i++) {  
                    json.push({"":data[i].detail," ":this.DatePipe.transform(data[i].dateTime,'dd/MM/yyyy - hh:mm a')})              
                    json.push({"":this.getUserName(data[i].userId)," ":''})

              }

        break;

                 }


                        }
                  }
                  else if(key.indexOf('memList')>=0){
                    json.push({"":feildName[i]});
                    let data=res[key.slice(0,key.indexOf('memList'))];
                    for (var i = 0; i < data.length; i++) {   
                      json.push({"":" "," ":this.getUserName(data[i])});
                    }
              }

                       else if(key.indexOf('ParaBreak')>=0)
                      {
                        let val=key.slice(0,key.indexOf('ParaBreak'));
                        json.push({"":feildName[i]," ":res[val]})

                      }
                   
                       else{
                        json.push({"":feildName[i]," ":res[key]})
                      }



                    });
                    json.push({"":" "," ":" "})


                  });


          if(json.length!=0)
          {
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
            //console.log('worksheet',worksheet);
            const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, header);
          }
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    
    this.loader.hide();
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);

  }


}
