import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HRbayService } from '../../service/hrbay.service';

@Injectable({
  providedIn: 'root'
})
export class VacationbalanceResolverService {

  constructor(private HRBayService:HRbayService) { }

  
  resolve(route: ActivatedRouteSnapshot){
    return this.HRBayService.getAllVacationBalance();

  }

}
