// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu .split-pane-visible > .split-pane-side {
  min-width: 70px !important;
  max-width: 60px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss","webpack://./../../LSS%20Web%20UI/lsswebui/src/app/app.component.scss"],"names":[],"mappings":"AACI;EACE,0BAAA;EACA,0BAAA;ACAN","sourcesContent":[".menu{\r\n    .split-pane-visible >.split-pane-side {\r\n      min-width: 70px!important;\r\n      max-width: 60px!important;\r\n    }\r\n    }",".menu .split-pane-visible > .split-pane-side {\n  min-width: 70px !important;\n  max-width: 60px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
