// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.file-upload-button {
  background-color: #ff5252;
  border-radius: 50%;
  height: 3rem;
  overflow: hidden;
  position: relative;
  width: 3rem;
}
.file-upload-button::after {
  content: "⇪";
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
}
.file-upload-button input[type=file] {
  font-size: 100px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./../../LSS%20Web%20UI/lsswebui/src/app/core/sharedComponent/popover/popover.component.scss","webpack://./src/app/core/sharedComponent/popover/popover.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;ACAhB;EACI,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;ADEJ;ACAE;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;ADEJ;ACCE;EACM,gBAAA;EACA,OAAA;EACA,UAAA;EACA,kBAAA;EACA,MAAA;ADCR","sourcesContent":["@charset \"UTF-8\";\n.file-upload-button {\n  background-color: #ff5252;\n  border-radius: 50%;\n  height: 3rem;\n  overflow: hidden;\n  position: relative;\n  width: 3rem;\n}\n.file-upload-button::after {\n  content: \"⇪\";\n  color: #fff;\n  font-size: 1.5rem;\n  text-align: center;\n}\n.file-upload-button input[type=file] {\n  font-size: 100px;\n  left: 0;\n  opacity: 0;\n  position: absolute;\n  top: 0;\n}",".file-upload-button {\r\n    background-color: #ff5252;\r\n    border-radius: 50%;\r\n    height: 3rem;\r\n    overflow: hidden;\r\n    position: relative;\r\n    width: 3rem;\r\n\r\n  &::after {\r\n    content: '⇪';\r\n    color: #fff;\r\n    font-size: 1.5rem;\r\n    text-align: center;\r\n  }\r\n\r\n  input[type=\"file\"] {\r\n        font-size: 100px;\r\n        left: 0;\r\n        opacity: 0;\r\n        position: absolute;\r\n        top: 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
